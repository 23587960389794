import React, { useEffect } from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import CustomCloudinaryVideoPlayer from "gatsby-theme-rothenberger/src/components/modules/CustomCloudinaryVideoPlayer"

const VideoPlayerDemoPage = () => {
  const vidFileName = "1000003391_ROBEND_4000E_Productvideo_DE.mp4"

  return (
    <Layout language="de">
      <BlockWrapper block={{}} blockPadding="py-2">
        <h2 className="headline headline-h2">Video Player Demo</h2>
        <div>
          <CustomCloudinaryVideoPlayer videoFilename={vidFileName} />
        </div>
      </BlockWrapper>
    </Layout>
  )
}

export default VideoPlayerDemoPage
